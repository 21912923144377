@charset "UTF-8";
@import url(./iconfont/iconfont.css);
body {
  font-size: 14px;
  color: #333;
  line-height: 1.8; }

a {
  color: #333;
  outline: 0; }

input {
  border: none; }
  input:focus {
    outline: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.tal {
  text-align: left; }

.tar {
  text-align: right; }

.tac {
  text-align: center; }

.taj {
  text-align: justify; }

.txo {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.current-color, a:hover {
  color: #d4a539; }

.container {
  width: 1150px;
  margin: 0 auto; }

.text-white, .nav-l li {
  color: #fff; }

.text-yellow-1 {
  color: #bea551; }

.text-yellow-2 {
  color: #a48413; }

.text-yellow-3, .nav-l .info, .nav-l .splitter, .nav-r li a.text-yellow-3, .nav-r li a:hover {
  color: #fdd23e; }

.text-green {
  color: #7ed5a0; }

.text-gray {
  color: #999; }

.text-red {
  color: #fd6281; }

.text-blue {
  color: #7f4dea; }

.mt-5 {
  margin-top: 5px; }

.mt-10 {
  margin-top: 10px; }

.mt-20 {
  margin-top: 20px; }

.mt-30 {
  margin-top: 30px; }

.fz12 {
  font-size: 12px; }

.fz14 {
  font-size: 14px; }

.fz16 {
  font-size: 16px; }

.fz18 {
  font-size: 18px; }

.fz20 {
  font-size: 20px; }

.fz22 {
  font-size: 22px; }

.fz24 {
  font-size: 24px; }

.separator {
  padding: 0 3px; }

.hide {
  display: none; }

.nowrap {
  white-space: nowrap; }

.line-through {
  text-decoration: line-through; }

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.clearfix {
  zoom: 1; }

.full {
  width: 100%; }

.m-btn {
  display: block;
  top: 19px;
  height: 45px;
  line-height: 45px;
  background: #fed547;
  background: -webkit-linear-gradient(#fedd6a, #fdd64c, #fdcf2f);
  font-size: 18px;
  color: #424242;
  box-shadow: 0 0 3px #dab73f;
  text-align: center; }
  .m-btn:hover {
    color: #fff; }

.filterGray {
  filter: grayscale(1);
  -webkit-filter: grayscale(1); }

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.align-center {
  display: flex;
  align-items: center; }

.progress-outter {
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 3px; }

.progress-inner {
  background-color: #fdd23c;
  height: 6px;
  border-radius: 3px; }

.nav {
  background-color: #424242;
  height: 35px;
  line-height: 35px;
  overflow: hidden; }

.nav-l {
  float: left; }
  .nav-l li {
    display: inline-block;
    background-position: 0 50%;
    padding-left: 20px;
    padding-right: 10px;
    background-repeat: no-repeat;
    background-image: url(https://img.jylc168.com/Pc/home/index/tel.png); }
  .nav-l .phone {
    padding-left: 15px;
    background-image: url(https://img.jylc168.com/Pc/home/index/mobile.png); }
  .nav-l .info, .nav-l .splitter {
    background-image: none; }
  .nav-l .splitter {
    position: relative;
    top: 4px;
    width: 1px;
    height: 16px;
    padding: 0;
    margin-right: 10px;
    background-color: #1b1b1b; }
  .nav-l .info {
    padding-left: 10px; }

.nav-r {
  float: right; }
  .nav-r li {
    display: inline-block; }
    .nav-r li a {
      color: #fff;
      padding: 0 10px; }

.empty-con {
  text-align: center;
  padding: 10px 0; }
  .empty-con .empty-desc {
    margin-top: 10px;
    color: #999; }

.pagination {
  font-size: 12px;
  line-height: 16px;
  text-align: right; }
  .pagination li {
    display: inline-block;
    position: relative;
    margin: 0 0 0 5px;
    border: 1px solid #bbb;
    border-radius: 2px;
    background: #fff;
    user-select: none; }
    .pagination li a {
      box-sizing: border-box;
      display: block;
      padding: 6px 9px;
      min-width: 30px;
      text-align: center;
      cursor: pointer; }
    .pagination li.disabled {
      border-color: #e5e5e5; }
      .pagination li.disabled a {
        background: #fff;
        color: #e5e5e5;
        cursor: not-allowed; }
    .pagination li.active {
      border-color: #a48d66; }
      .pagination li.active a {
        background: #a48d66;
        color: #fff; }
    .pagination li *.disabled {
      border-color: #e5e5e5;
      cursor: not-allowed;
      color: #e5e5e5; }

.load-more {
  margin-top: 25px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  color: #a18b64;
  letter-spacing: 1px; }
  .load-more i {
    padding-right: 5px; }

/*银币尊享*/
.jy-mt-30 {
  margin-top: 30px; }

.jy-mt-20 {
  margin-top: 20px; }

.jy-text-fff {
  color: #fff; }

.silver-head {
  height: 52px;
  line-height: 52px;
  background: url(https://img.jylc168.com/Asset/User/common/image/account/silver-icon1.png) no-repeat 0 0;
  font-size: 14px;
  padding-left: 33px; }

.silver-head .silver-ml {
  margin-left: 120px; }

.silver-green {
  color: #a08a64; }

.silver-back {
  margin-top: 40px;
  height: 155px;
  background: url(https://img.jylc168.com/Asset/User/common/image/account/silver-icon2.png) no-repeat 38px 0; }

.silver-back li {
  float: left;
  width: 293px;
  text-align: center; }

.silver-money {
  height: 108px;
  line-height: 108px;
  font-size: 14px;
  color: #fff; }

.silver-lists {
  background: url(https://img.jylc168.com/Asset/User/common/image/account/silver-icon3.png) no-repeat 74px 7px;
  padding-left: 106px;
  font-size: 14px;
  line-height: 30px; }

.silver-lists1 {
  background-position: 52px 7px;
  padding-left: 80px; }

.silver-grade {
  margin-top: 45px; }

.silver-grade li {
  position: relative;
  float: left;
  width: 253px;
  height: 347px;
  background: url(https://img.jylc168.com/Asset/User/common/image/account/silver-icon4.png) no-repeat 0 0;
  margin: 0 25px; }

.silver-grade .silver-mask {
  position: absolute;
  z-index: 2;
  top: 120px;
  left: 36px; }

.grade-head {
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 56px;
  height: 56px;
  margin-top: 28px; }

.grade-text {
  margin-top: 37px;
  padding-left: 47px; }

.grade-text1 {
  padding-left: 40px; }

.grade-text.grade-text1 dd {
  width: 180px;
  height: 42px;
  background-image: url(https://img.jylc168.com/Asset/User/common/image/account/silver-icon7.png);
  background-position: 28px 0px; }

.grade-text dd {
  position: relative;
  width: 160px;
  height: 42px;
  line-height: 42px;
  background: url(https://img.jylc168.com/Asset/User/common/image/account/silver-icon6.png) no-repeat 31px 0;
  margin-bottom: 10px;
  font-size: 14px;
  color: #fff; }

.grade-text dd input {
  margin-right: 26px; }

.grade-text dd b {
  font-size: 24px;
  color: #6d501d; }

.silver-tip {
  margin-left: 3px;
  position: relative; }

.silver-tip:hover .silver-tip-txt {
  display: block; }

.silver-tip-txt {
  position: absolute;
  width: 142px;
  height: 41px;
  background: url(https://img.jylc168.com/Asset/User/common/image/account/silver-icon8.png) no-repeat 0 0;
  left: 14px;
  top: -12px;
  padding-left: 11px;
  z-index: 1;
  line-height: 14px;
  color: #7d7d7d;
  padding-top: 6px;
  font-size: 12px;
  display: none; }

.silver-tip-txt1 {
  line-height: 30px; }

.silver-btn {
  display: block;
  background: url(https://img.jylc168.com/Asset/User/common/image/account/silver-icon9.png) no-repeat 0 0;
  width: 253px;
  height: 44px;
  line-height: 44px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto; }

.silver-btn:hover {
  color: #fff; }

.silver-hint {
  width: 868px;
  height: 138px;
  border: 1px dashed #cfbe90;
  background: #f5f2e9 url(https://img.jylc168.com/Asset/User/common/image/account/silver-icon11.png) no-repeat 27px 50%;
  margin-left: 10px; }

.silver-hint ul {
  padding-left: 120px;
  margin-top: 19px; }

.silver-hint ul li {
  line-height: 26px; }

.checkDetail {
  white-space: pre-line; }
